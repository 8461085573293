import DiffMatchPatch from "diff-match-patch";
import type { IHistoryRow } from "~/types/generalData.interface";
import type {
  ITopData,
  IBottomData,
  ISaved,
} from "~/types/singleData.interface";

export function validateData(jsonData: object) {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const testingOjb = {
    summary: jsonData?.summary,
    skills: jsonData?.skills,
    experiences: jsonData?.experiences,
    languages: jsonData?.languages,
    educations: jsonData?.educations,
    certifications: jsonData?.certifications,
  };

  if (!testingOjb.summary.summary) {
    testingOjb.summary = {
      job_title: testingOjb.summary.job_title,
      summary_title: testingOjb.summary.summary_title,
      trigram: testingOjb.summary.trigram,
      summary: t("view.noContent"),
    };
  }
  if (!testingOjb.skills.skills) {
    testingOjb.skills = { skills: [] };
  }
  if (!testingOjb.experiences.content) {
    testingOjb.experiences = { content: [] };
  }
  if (!testingOjb.languages.languages) {
    testingOjb.languages = { languages: [] };
  }
  if (!testingOjb.educations.educations) {
    testingOjb.educations = { educations: [] };
  }
  if (!testingOjb.certifications.certifications) {
    testingOjb.certifications = { certifications: [] };
  }
  return testingOjb;
}

export function validateExperience(input: any) {
  return input.replace(/\D/g, "");
}

export function setSave(inputTopData: ITopData, inputBottomData: IBottomData) {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const errorCheckGrid = {} as ISaved;

  // JobTitle
  if (
    !inputTopData.job_title ||
    (inputTopData.job_title && inputTopData.job_title === "")
  ) {
    errorCheckGrid.jobTitleCheck = {
      check: false,
      errorMessage: t("view.events.errors.jobTitle"),
      section: "cfTop",
    };
  } else {
    errorCheckGrid.jobTitleCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "cfTop",
    };
  }

  // Trigram
  if (
    !inputTopData.trigram ||
    (inputTopData.trigram &&
      (inputTopData.trigram === "" || inputTopData.trigram.length != 3))
  ) {
    errorCheckGrid.trigramCheck = {
      check: false,
      errorMessage: t("view.events.errors.trigram"),
      section: "cfTop",
    };
  } else {
    errorCheckGrid.trigramCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "cfTop",
    };
  }

  // Experience
  if (
    !inputTopData.experience ||
    (inputTopData.experience &&
      (inputTopData.experience === "" ||
        inputTopData.experience.toString().length > 2 ||
        inputTopData.experience.length > 2))
  ) {
    errorCheckGrid.experienceCheck = {
      check: false,
      errorMessage: t("view.events.errors.xp.empty"),
      section: "cfTop",
    };
  } else if (
    !inputTopData.experience ||
    (inputTopData.experience &&
      (inputTopData.experience === "" ||
        inputTopData.experience === "0" ||
        inputTopData.experience.toString() === "0"))
  ) {
    errorCheckGrid.experienceCheck = {
      check: false,
      errorMessage: t("view.events.errors.xp.zero"),
      section: "cfTop",
    };
  } else {
    errorCheckGrid.experienceCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "cfTop",
    };
  }

  // Editor - Summary
  if (
    !inputBottomData.summary ||
    (inputBottomData.summary &&
      inputBottomData.summary.includes(t("view.events.noContent")))
  ) {
    errorCheckGrid.summaryCheck = {
      check: false,
      errorMessage: t("view.events.errors.summary"),
      section: "editor_Summary",
    };
  } else {
    errorCheckGrid.summaryCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "editor_Summary",
    };
  }

  // Editor - Skills
  if (
    !inputBottomData.skills ||
    (inputBottomData.skills && inputBottomData.skills.includes("unknown")) ||
    inputBottomData.skills.includes("Unknown")
  ) {
    errorCheckGrid.skillsCheck = {
      check: false,
      errorMessage: t("view.events.errors.skills.unknowns"),
      section: "editor_Skills",
    };
  } else if (
    !inputBottomData.skills ||
    (inputBottomData.skills &&
      inputBottomData.skills.includes(t("view.events.noContent")))
  ) {
    errorCheckGrid.skillsCheck = {
      check: false,
      errorMessage: t("view.events.errors.skills.empty"),
      section: "editor_Skills",
    };
  } else {
    errorCheckGrid.skillsCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "editor_Skills",
    };
  }

  // Editor - Project

  // Editor - Languages
  if (
    !inputBottomData.languages ||
    (inputBottomData.languages &&
      inputBottomData.languages.includes("unknown")) ||
    inputBottomData.languages.includes("Unknown") ||
    inputBottomData.languages.includes(t("view.events.noContent"))
  ) {
    errorCheckGrid.languagesCheck = {
      check: false,
      errorMessage: t("view.events.errors.languages"),
      section: "editor_Languages",
    };
  } else {
    errorCheckGrid.languagesCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "editor_Languages",
    };
  }

  // Editor - Educations
  if (
    !inputBottomData.educations ||
    (inputBottomData.educations &&
      inputBottomData.educations.includes("unknown")) ||
    inputBottomData.educations.includes("Unknown") ||
    inputBottomData.educations.includes(t("view.events.noContent"))
  ) {
    errorCheckGrid.educationsCheck = {
      check: false,
      errorMessage: t("view.events.errors.educations"),
      section: "editor_Educations",
    };
  } else {
    errorCheckGrid.educationsCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "editor_Educations",
    };
  }

  // Editor - Certifications
  if (
    !inputBottomData.certifications ||
    (inputBottomData.certifications &&
      inputBottomData.certifications.includes("unknown")) ||
    inputBottomData.certifications.includes("Unknown") ||
    inputBottomData.certifications.includes(t("view.events.noContent"))
  ) {
    errorCheckGrid.certificationsCheck = {
      check: false,
      errorMessage: t("view.events.errors.certifications"),
      section: "editor_Certifications",
    };
  } else {
    errorCheckGrid.certificationsCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "editor_Certifications",
    };
  }

  let sum = true;

  Object.entries(errorCheckGrid).forEach(([key, value]) => {
    sum = value.check && sum;
  });
  if (inputTopData.size > 1 && !sum) {
    errorCheckGrid.multiCheck = {
      check: false,
      errorMessage: t("view.events.coreMulti"),
      section: "cfTop",
    };
  } else {
    errorCheckGrid.multiCheck = {
      check: true,
      errorMessage: t("view.events.errors.noMessage"),
      section: "cfTop",
    };
  }

  return { sum: sum, grid: errorCheckGrid };
}

export function valdidateDates(
  element: object,
  job_title: string,
  template_id: string,
  missionNeedToken: string,
  companyName: string,
): IHistoryRow {
  const creation_date = new Date(element.creation_date);
  const expiration_date = new Date(element.creation_date);
  expiration_date.setDate(expiration_date.getDate() + 29);
  const today_date = new Date();
  const expired = today_date.getTime() > expiration_date.getTime();

  let daysDiff = 0;
  let status = "";

  if (!expired) {
    daysDiff =
      30 -
      Math.round(
        (today_date.getTime() - creation_date.getTime()) / (1000 * 3600 * 24),
      );
    status = element.status;
  } else {
    status = "EXPIRED";
  }

  const obj: IHistoryRow = {
    creation_date: element.creation_date,
    formatted_date: formatDate(creation_date),
    expired: expired,
    time_left: daysDiff,
    token: element.token,
    status: status,
    trigram: element.trigram,
    job_title: job_title,
    template_id: template_id,
    canRetry: element.can_retry,
    uuid: element.uuid,
    missionNeedToken: missionNeedToken,
    companyName: companyName,
  };

  return obj;
}

function formatDate(date: Date): string {
  const hours = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
  const minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();

  return `${hours}:${minutes} • ${date.toLocaleDateString()}`;
}

export function checkDiffs(text1: string, text2: string) {
  const dmp = new DiffMatchPatch();
  const text1_clean = text1.replace(/<[^>]*>/g, "");
  const text2_clean = text2.replace(/<[^>]*>/g, "");
  const diffs = dmp.diff_main(text1_clean, text2_clean);
  const tmp_diffs = [] as DiffMatchPatch.Diff[];
  diffs.forEach((diff) => {
    if (diff[0] != 0 && !diff[1].includes("\n")) {
      tmp_diffs.push(diff);
    }
  });

  return dmp.diff_prettyHtml(tmp_diffs);
}

export function dateTransform(
  dateContent: string,
  contentLanguage: string,
): Date {
  const dateParts = dateContent.split(" - ");
  const startDateParts = dateParts[0].split(" ");

  enum Mois {
    janvier = "01",
    février = "02",
    mars = "03",
    avril = "04",
    mai = "05",
    juin = "06",
    juillet = "07",
    août = "08",
    septembre = "09",
    octobre = "10",
    novembre = "11",
    décembre = "12",
  }

  let startD = new Date(dateParts[0]);

  if (contentLanguage === "French") {
    const month = Mois[startDateParts[0].toLowerCase() as keyof typeof Mois];
    const year = startDateParts[1];
    startD = new Date(`${year}-${month}-01`);
  }
  return startD;
}
